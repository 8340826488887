import React, { useState, useEffect, Fragment } from "react";
import ChainedContext from "../../../../contexts/chainedContext";
import ContactReceiver from "../../../../contexts/contact_context/Contact_Receiver";
import AuthenticationReceiver from "../../../../plugins/clio_auth/_components/authenticationReceiver";
import {
  Input,
  Dropdown,
  TextArea,
  Group,
  Button,
  DateSelect,
} from "../../../../components/Forms";
import API from "../../../../api";
import CommonFunctions from "../../../../CommonFunctions";
import Segment from "../../../../components/Segment";
import {PencilIcon, PlusIcon, SaveAsIcon, XIcon } from "@heroicons/react/solid";
import ClientBreadcrumbs from "../../components/ClientBreadcrumbs";
import { GiMoneyStack } from "react-icons/gi";
import { Dialog, Transition } from "@headlessui/react";
import dayjs from "dayjs";
import ConfirmModal from "../../../../components/Modals/ConfirmModal";
import toast from "react-hot-toast";
import { v4 as uuid } from "uuid";
import RatesTable from "../../../../components/Tables/ratesTable";
import Loader from "../../../../components/Loader";

const Add = ({ history, contact, auth }) => {
  const [data, setData] = useState({
    description: "",
    client_reference: "",
    location: "",
    practice_area: "",
    responsible_attorney: "",
    originating_attorney: "",
    "fee_set": "",
    "upfront_fee": contact.settings.upfront_fee ? contact.settings.upfront_fee : 0,
    "upfront_fee_type": contact.settings.upfront_fee_type ? contact.settings.upfront_fee_type : "fixed",
    "fee_thresholds": contact.settings.fee_thresholds ? contact.settings.fee_thresholds : [],
    "scale": contact.settings.scale ? contact.settings.scale : "slidingScale",
    areaTask: -1,
    xero_send: "instant"
  });
  const [customData, setCustomData] = useState({});
  const [clioUsers, setClioUsers] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [practiceArea, setPracticeArea] = useState([]);

  const [openInterestModal, setOpenInterestModal] = useState(false)
  const [interestModalStage, setInterestModalStage] = useState("");
  const [latePaymentsBaseRate, setLatePaymentsBaseRate] = useState({});
  const [baseRate, setBaseRate] = useState({});

  const [feesModal, setFeesModal] = useState(false);
  const [showSelectSet, setShowSelectSet] = useState(false);
  const [areaTasks, setAreaTasks] = useState([
    {text: "None", value: -1}
  ]);
  const [feeSets, setFeeSets] = useState([]);

  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {
    if (mountLoading) {

      let theData = data;

      API.settings.area_tasks.getAll().then(res => {
        const tasksBuilder = res.map((a) => {
          return {
            text: a.area,
            value: a._id,
          };
        });

        setAreaTasks(areaTasks.concat(tasksBuilder));
      })

      API.settings.doc_types().list().then((res) => {
        const practice_areas = res.map((a) => {
          return {
            text: a.name,
            value: a._id,
            // value: a.id,
          };
        });

        setPracticeArea(practice_areas);

        /*

        if (
          practice_areas.find((item) => item.value === 885229) !== undefined
        ) {
          theData.practice_area = 885229;
        } */

        API.users.getAll().then((res) => {
          setClioUsers(
            res.users.map((a) => {
              if(a.email == auth.user.email){
                theData.responsible_attorney = a._clioId;
                // setData({ ...data, responsible_attorney: a.id });
              }

              return {
                text: a.name,
                value: a._clioId,
              };
            })
            
          );

            setData({ ...data, 
              practice_area: theData.practice_area ? theData.practice_area : "", 
              responsible_attorney: theData.responsible_attorney });
          
        });

      });

      API.customFields.list().then((res) => setCustomFields(res.filter(e => e.type === "matter" && !e.archived)));

      API.settings.base_rates.late_payments.get().then((res )=> {
        let f2 = res.filter(e => dayjs().diff(e.date) >= 0);

        if(f2[0]){
          setLatePaymentsBaseRate(f2[0]);
        }else{
          setLatePaymentsBaseRate(res[0]);
        }
      })

      API.settings.base_rates.normal.get().then((res )=> {
        let f2 = res.filter(e => dayjs().diff(e.date) >= 0);

        if(f2[0]){
          setBaseRate(f2[0]);
        }else{
          setBaseRate(res[0]);
        }
      })

      API.settings.fee_scales().list().then((res) => {
        setFeeSets(res);
      })

      setMountLoading(false);
    }
  }, [mountLoading, data]);

  const onChange = (e) => {

    let newData = {...data, [e.target.name]: e.target.value};

    if(e.target.name === "billing_method" && e.target.value !== "hourly"){
      newData.billable = false;
    }

    setData(newData);
  };

  const onCustomChange = (e) => {
    setCustomData({ ...customData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    API.contacts.matters
      .create(contact._id, {...data, description: contact.name, custom_fields: customData, interestType: interestModalStage})
      .then((res) => {
        if(res.success) {
          history.push(`/matters/${res._id}/debtors/add`);
        }
      })
      .catch((e) => CommonFunctions.handleError(e))
      .finally(() => {});
  };

  const interestModal = () => {
    return (
      <Transition.Root show={openInterestModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpenInterestModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
  
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                      <GiMoneyStack className="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Interest Rate
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Contract with Interest Specified?
                          <span className="ml-2 isolate inline-flex rounded-md shadow-sm">
                            <button
                              type="button"
                              className={`${interestModalStage.includes("contractual") && "bg-primary"} relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => setInterestModalStage("contractual")}
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className={`${interestModalStage.includes("bussiness") && "bg-primary"} relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => setInterestModalStage("bussiness")}
                            >
                              No
                            </button>
                          </span>
                        </p>
                        {interestModalStage && interestModalStage.includes("contractual") &&
                        <p className="text-sm text-gray-500 mt-2">
                          Specified flat rate?
                          <span className="ml-2 isolate inline-flex rounded-md shadow-sm">
                          <button
                              type="button"
                              className={`${interestModalStage == "contractual_flat" && "bg-primary"} relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => {setInterestModalStage("contractual_flat"); setData({...data, interest_rate: baseRate.rate})}}
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className={`${interestModalStage == "contractual_nonflat" && "bg-primary"} relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => {
                                setInterestModalStage("contractual_nonflat");
                                setData({
                                  ...data,
                                  interest_rate: parseFloat(data.interest_rate_percent ? data.interest_rate_percent : 0) + parseFloat(data.interest_rate_base ? data.interest_rate_base : baseRate.rate)
                                })
                              
                              }}
                            >
                              No
                            </button>
                          </span>
                        </p>}
                        {interestModalStage && interestModalStage == "contractual_flat" &&
                        <p className="text-sm text-gray-500 mt-2">
                          <Input
                            label="Flat Rate"
                            name="interest_rate"
                            value={data.interest_rate}
                            onChange={onChange}
                          />
                        </p>}
                        {interestModalStage && interestModalStage == "contractual_nonflat" &&
                        <p className="text-sm text-gray-500 mt-2">
                          <Input
                            label="Percentage Specified"
                            name="interest_rate_percent"
                            value={data.interest_rate_percent ? data.interest_rate_percent : 0}
                            onChange={(e) => {
                              setData({ 
                                ...data, 
                                [e.target.name]: e.target.value,
                                interest_rate: parseFloat(e.target.value) + parseFloat(data.interest_rate_base ? data.interest_rate_base : baseRate.rate)
                                });
                            }}
                          />
                          <Input
                            label="Base Rate"
                            name="interest_rate_base"
                            value={data.interest_rate_base ? data.interest_rate_base : baseRate.rate}
                            onChange={(e) => {
                              setData({ 
                                ...data, 
                                [e.target.name]: e.target.value,
                                interest_rate: parseFloat(e.target.value > 0 ? e.target.value : baseRate.rate) + parseFloat(data.interest_rate_percent)
                                });
                            }}
                          />
                          Calculated Rate: {data.interest_rate}
                        </p>}
                        {interestModalStage && interestModalStage.includes("bussiness") &&
                        <p className="text-sm text-gray-500 mt-2">
                          Business to Business contract?
                          <span className="ml-2 isolate inline-flex rounded-md shadow-sm">
                            <button
                              type="button"
                              className={`${interestModalStage == "bussiness_bussiness" && "bg-primary"} relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => {
                                setInterestModalStage("bussiness_bussiness");
                                setData({ 
                                  ...data, 
                                  interest_rate: (latePaymentsBaseRate ? latePaymentsBaseRate.rate : 0) //+ (baseRate ? baseRate.rate : 0)
                                });
                              }}
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className={`${interestModalStage == "bussiness_simple" && "bg-primary"} relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => {
                                setInterestModalStage("bussiness_simple");
                                setData({ 
                                  ...data, 
                                  interest_rate: 8
                                });
                              }}
                            >
                              No
                            </button>
                          </span>
                        </p>}
                        {interestModalStage && (interestModalStage == "bussiness_simple") &&
                        <p className="text-sm text-gray-500 mt-2">
                          Simple interest rate at 8%  (s69 County Court Act 1984/s35a Senior Courts Act 1981)
                        </p>}
                        {interestModalStage && (interestModalStage == "bussiness_bussiness") &&
                        <p className="text-sm text-gray-500 mt-2">
                          Late Payment of Commerical Debts (Interest) Act applies (8% + Base): {(latePaymentsBaseRate && latePaymentsBaseRate.rate)} <br/>
                          Current Interest Base rate of Bank of England: {baseRate && baseRate.rate} <br/>
                          Calculated Rate: {data.interest_rate}
                        </p>}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                  <Button
                    colour="alert"
                    onClick={() => setOpenInterestModal(false)}
                    label="Close"
                  />
                </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  };

  const UpdateFees = () => {
    API.contacts
      .updateSettings(contact._id, data)
      .then(() => {
        toast.success(`Fees updated.`);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        contact._refresh();
        setFeesModal(false);
      });
  }

  const FeesDialog = () => {
    return (
      <Transition
        show={feesModal}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        {showSelectSet && 
        <ConfirmModal
          handleClose={() => setShowSelectSet(false)}
          open={true}
          header={"Load Fee Set?"}
          content={
            "Are you sure you like to load this Fee Set, all fee values will be rest with this set values. This data is only saved once Fees is confirmed!"
          }
          handleSubmit={() => {
            let set = feeSets.find(set => set._id.toString() === data.fee_set);

            setData({ 
              ...data, 
              upfront_fee: set.upfront_fee,
              upfront_fee_type: set.upfront_fee_type,
              fee_thresholds: set.thresholds,
              scale: set.scale
            });
            setShowSelectSet(false);
          }}
        /> }
        <Dialog
          open={feesModal}
          onClose={() => setFeesModal(false)}
          className="fixed z-9 inset-0 overflow-y-auto"
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className=" inline-block w-full max-w-5xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="text-xl">
                <Dialog.Title>
                  Set/Override Fees
                  <div className="floated-right">
                    <select
                      name="fee_set"
                      value={data.fee_set}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md bg-gray-200"
                      placeholder={"Select"}
                      onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                    >
                      <option disabled selected={data.fee_set == ""} hidden value="">Choose an option</option>
                      {feeSets.map((set, index) => { return <option key={index} value={set._id.toString()}>{set.name} ({set.scale})</option>})}
                    </select>
                    <Button
                      disabled={!data.fee_set}
                      colour="warning"
                      label="Load Set"
                      onClick={() => setShowSelectSet(true)}
                      icon={SaveAsIcon}
                    />
                    
                  </div>
                </Dialog.Title>
              </div>
              <div className="flex flex-col px-2 py-4 border-t border-gray-300 mt-2 justify-center align-middle">
                <div className="flex flex-row gap-2">
                  <label
                    htmlFor="upfront_fee"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Upfront Fee (setup / LBA)
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="number"
                      name="upfront_fee"
                      id="name"
                      value={data.upfront_fee}
                      onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                      className="w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:max-w-xs sm:text-sm"
                    />
                    <select
                        name="upfront_fee_type"
                        value={data.upfront_fee_type}
                        className="mt-1 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md bg-gray-200"
                        placeholder={"Select"}
                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                      >
                        <option value={"fixed"}>
                            £
                        </option>
                        <option value={"percent"}>
                            %
                        </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex flex-col px-2 py-4 border-t border-gray-300 mt-2 justify-center align-middle">
                <div className="flex flex-row gap-2">
                  <label
                    htmlFor="slidingScale"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Sliding Scale for Success Fees:
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input 
                      type="radio"
                      name="feeScale" // Group name to allow only one selection
                      value="slidingScale"
                      checked={data.scale === 'slidingScale'}
                      onChange={() => setData({ ...data, scale: 'slidingScale' })} // Update state
                    />
                  </div>
                </div>

                <div className="flex flex-row gap-2">
                  <label
                    htmlFor="stageScale"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Stage Scale for Success Fees:
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input 
                      type="radio"
                      name="feeScale" // Same name for the radio group
                      value="stageScale"
                      checked={data.scale === 'stageScale'}
                      onChange={() => setData({ ...data, scale: 'stageScale' })}
                    />
                  </div>
                </div>

                <div className="flex flex-row gap-2">
                  <label
                    htmlFor="hourlyScale"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Hourly Scale for Success Fees:
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input 
                      type="radio"
                      name="feeScale" // Same name for the radio group
                      value="hourlyScale"
                      checked={data.scale === 'hourlyScale'}
                      onChange={() => setData({ ...data, scale: 'hourlyScale' })}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col px-2 py-4 border-t border-gray-300 mt-2 justify-center align-middle">
                <div className="flex flex-row gap-2">
                  <label
                    htmlFor="upfront_fee"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Rates:
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <RatesTable scale={data.scale} rates={data.fee_thresholds ? data.fee_thresholds.sort((a, b) =>
                    a.threshold > b.threshold ? 1 : a.threshold < b.threshold ? -1 : 0
                  ) : []} 
                  onCreate={(d) => {
                    let thresholds = data.fee_thresholds;

                    thresholds.push({
                      _id: "custom_" + uuid(),
                      fee : d.fee,
                      over_threshold : d.over,
                      threshold : d.threshold,
                      type : d.type,
                      stage : d.stage,
                      scale : d.scale,
                    })

                    return setData({...data, fee_thresholds: thresholds})

                  }}
                  onUpdate={(id, d) => {
                    let thresholds = data.fee_thresholds;
                    let threshold = thresholds.find(item => item._id == id);

                    if(threshold) {
                      threshold.fee = d.fee;
                      threshold.over_threshold = d.over;
                      threshold.threshold = d.threshold;
                      threshold.type = d.type;
                      threshold.stage = d.stage;
                      threshold.scale = d.scale;
                    }

                    return setData({...data, fee_thresholds: thresholds})

                  }}
                  onDelete={(id) => {
                    let thresholds = data.fee_thresholds;
                    let threshold = thresholds.findIndex(item => item._id == id);

                    if(threshold != -1){
                      thresholds.splice(threshold, 1);
                    }

                    return setData({...data, fee_thresholds: thresholds})
                  }} />
                  </div>
                </div>
              </div>
              <div className="flex justify-between mt-4 border-t border-gray-300 pt-4">
                <Button
                  colour="alert"
                  label="Close"
                  onClick={() => setFeesModal(false)}
                  icon={XIcon}
                />
{/*  
                <Button
                  colour="green"
                  label="Confirm"
                  onClick={UpdateFees}
                  icon={CheckIcon}
                />*/}
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (

    mountLoading ? <Loader /> :

    <div>
      {interestModal()}
      {FeesDialog()}
      <ClientBreadcrumbs panes={[
        { name: `Client - ${contact && `${contact.name ? contact.name : `${contact.first_name} ${contact.last_name}`} ${contact.suffix ? contact.suffix : ""}`}}`, href: `/contacts/${contact._id}`, current: false },
        { name: "Matters", href: `/contacts/${contact._id}/matters`, current: false},
        { name: "Add", current: true}
      ]} />
      <Segment className={"mt-4"}>
        <div className="divide-gray-300">
          <div className="">
            <h3 className=" leading-6 font-medium text-xl text-gray-900">
              Matter Information
            </h3>
            <div className="w-full border-t border-gray-300 my-2" />
          </div>
          <div className="py-2">
            <TextArea
              label="Matter Description"
              name="description"
              value={data.description}
              onChange={onChange}
            />
          </div>
          <Group className="py-2">
            {/*  
          <div class=""><label for="" class="block text-sm font-medium text-gray-700">Display Number</label><div class="">
            Generated on create in format of #ClientNumber.#MatterNumber</div></div>
            <Input
              label="Client Reference Number"
              name="client_reference"
              value={data.client_reference}
              onChange={onChange}
            />*/}
            {/*  
            <Input
              label="Location"
              name="location"
              value={data.location}
              onChange={onChange}
            />*/}
            <Dropdown
              label={"Area of Law"}
              name={"practice_area"}
              value={data.practice_area}
              onChange={onChange}
              options={practiceArea}
            />
            <div>
              <Dropdown
                label={"Area of Law Workflow"}
                name={"areaTask"}
                value={data.areaTask}
                onChange={onChange}
                options={areaTasks}
              />
              <p>Note: This is not available on Edit Matter as on creation the Workflow tasks are generated and saved against the Matter!</p>
            </div>
          </Group>
          <Group className="py-2">
            <Dropdown
              label={"Fee Earner"}
              name={"responsible_attorney"}
              value={data.responsible_attorney}
              onChange={onChange}
              options={clioUsers}
            />
            <Dropdown
              label={"Supervisor"}
              name={"originating_attorney"}
              value={data.originating_attorney}
              onChange={onChange}
              options={clioUsers}
            />
            <div className="w-full">
              Current Interest Rate: {data.interest_rate}
              <Button
                colour="action"
                label="Interest Rate"
                onClick={() => setOpenInterestModal(true)}
                icon={GiMoneyStack}
              />
            </div>
            <label
              for="billable">
              Billable
            </label>
            <input
                id="billable"
                name="billable"
                type="checkbox"
                onChange={() => setData({ ...data, billable: !data.billable })}
                checked={data.billable}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <Dropdown
              label={"Billing Method"}
              name={"billing_method"}
              value={data.billing_method ? data.billing_method : ""}
              onChange={onChange}
              options={[
                {key: 1, text: "Hourly", value: "hourly"},
                {key: 2, text: "Fixed", value: "fixed"},
                {key: 3, text: "Contingency", value: "contingency"},
              ]}
            />
            {data.billing_method === "contingency" &&  
            <Input
              label="Contingency Percentage"
              name="contingency_percentage"
              value={data.contingency_percentage ? data.contingency_percentage : 0}
              onChange={onChange}
            />}
            {/*  
            <Input
              label="Interest Rate"
              name="interest_rate"
              value={data.interest_rate ? data.interest_rate : 0}
              onChange={onChange}
            />*/}
          </Group>
        </div>

        <div className="mt-5 divide-gray-300">
          <div className="">
            <h3 className=" leading-6 font-medium text-xl text-gray-900">
              Fee Rates
            </h3>
            <div className="w-full border-t border-gray-300 my-2" />
          </div>
          <div className="py-2">
            <dd className="my-auto text-sm text-gray-900">
              <span>
                <ul>
                  <li> Upfront Fee (setup / LBA): {" "}
                    {data.upfront_fee ? `${data.upfront_fee_type == "fixed" ? "£" : ""}${data.upfront_fee}${data.upfront_fee_type == "percent" ? "%" : ""}` : `Not Set!!`} 
                  </li>
                  {data.scale == "slidingScale" && "Sliding Scale for Success Fee:"}
                  {data.scale == "stageScale" && "Stage Scale for Success Fees:"}
                  {data.scale == "hourlyScale" && "Hourly Scale for Success Fees:"}
                    
                    {data.fee_thresholds && data.fee_thresholds.length > 0 ? 
                    data.fee_thresholds.filter(e => e.scale == data.scale).map((fee, index) => <li key={index}>
                    - 
                      {fee.scale == "stageScale" ? <>
                        Stage: {fee.stage} - Fee: £{fee.fee.toLocaleString()}
                      </> :
                      <>
                        {`${fee.over_threshold ? "Over:" : "Up to:"} £${fee.threshold.toLocaleString()}`} - Fee: {fee.type === "fixed" ? "£" : ""}{fee.fee.toLocaleString()}{fee.type === "percent" ? "%" : ""}
                      </>}

                  </li>) : 
                    <li>No Threshold Fees Set!!</li>}
                  {/* <li>Up to: £200 - £80 fee</li> */}
                </ul>
              </span>
            </dd>
            <dd>
              <Button
                onClick={() => {
                  setFeesModal(true);
                }}
                label="Set Fees"
                className="block ml-auto"
                colour="warning"
                icon={PencilIcon}
              />
            </dd>
          </div>
        </div>

            <Dropdown
              label={"Xero Invoices"}
              name={"xero_send"}
              value={data.xero_send ? data.xero_send : ""}
              onChange={onChange}
              options={[
                {key: 1, text: "Instant - Sends to Xero as it comes in", value: "instant"},
                {key: 2, text: "Manual - No Automatic sending", value: "manual"},
                {key: 3, text: "Weekly Joint - Joins Invoices together and sends weekly", value: "weekly_joint"},
                {key: 4, text: "Monthly Joint - Joins Invoices together and sends at end of month", value: "monthly_joint"},
              ]}
            />

        <div className="mt-5 divide-gray-300">
          <div className="">
            <h3 className=" leading-6 font-medium text-xl text-gray-900">
              Custom Fields
            </h3>
            <div className="w-full border-t border-gray-300 my-2" />
          </div>
          {/*  
          <div className="py-2">
            <TextArea
              label="Matter Description"
              name="description"
              value={data.description}
              onChange={onChange}
            />
          </div>*/}
          <div className="py-2">
            {customFields.length > 0 ? customFields.map((field) => {
            let field_name = (field.name).replace(" ", "_").toLowerCase();
              switch(field.fieldType){           
                case "date":
                  return <DateSelect name={field_name} onChange={(e) => setCustomData({...customData, [field_name]:e})} value={customData[field_name]} label={field.description} />
                case "checkbox":
                  return  <div className="mt-5 relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id={field_name}
                      aria-describedby="comments-description"
                      name={field_name}
                      type="checkbox"
                      onChange={() => setCustomData({...customData, [field_name]: customData[field_name] ? !customData[field_name] : true })}
                      checked={customData[field_name]}
                      className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor={field_name} className="font-medium text-gray-900">
                      {field.description}
                    </label>
                  </div>
                </div>
              }
            }) : "No Additonal Fields"}
          </div>
          <div className="pt-2 flex justify-between">
            <Button
              icon={XIcon}
              colour="alert"
              label="Cancel"
              onClick={() =>
                history.push("/contacts/" + contact._id + "/matters")
              }
            />
            <Button
              colour="positive"
              label="Create"
              onClick={handleSubmit}
              icon={PlusIcon}
            />
          </div>
        </div>
      </Segment>
    </div>
  );
};

export default ChainedContext(Add, [
  [
    ContactReceiver,
    (contact) => {
      return { contact };
    },
  ],
  [
    AuthenticationReceiver,
    (auth) => {
      return { auth };
    },
  ]
]);
