import React from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "../../components/NotFound";
import { Navbar } from "./_components";
import SettingsProvider from "../../contexts/settings_context/Settings_Provider";
import API from "../../api";

import Dash from "./dashboard";
import BaseRates from "./base_rates";
import ClioDocs from "./clio_docs";
import AreaTasks from "./area_tasks";
import Checklists from "./checklists";
import Services from "./services";
import Email_Templates from "./emailTemplates";
import Doc_Templates from "./documentTemplates";
import Doc_Types from "./documentTemplateTypes";
import Categories from "./activityCategories";
import { Authentication } from "../../plugins/clio_auth";
import Users from "./users";
import LetterHeading from "./letterHeadings";
import Correspondents from "./correspondents";
import ContactCustomFields from "./custom_fields/contactFields";
import MatterCustomFields from "./custom_fields/matterFields";
import FeeScaleSettings from "./fee_scales"
import PaymentTypesSettings from "./payment_types";
import SalutationsSettings from "./salutations";
import CourtsSettings from "./courts";
import Account from "./account";
import AccountEdit from "./account_edit";
import Purchase_License from "./account_purchase";
import Whitelabeling from "./whitelabeling";
import AgentsSettings from "./agents";

const routePrefix = "/settings";

export default function Index({ history, match }) {

  if(!Authentication.can("settings.view")){
    return <Switch><Route path="*" component={NotFound} /></Switch>;
  }

  return (
    <div>
      <SettingsProvider api={API}>
        <Navbar history={history} match={match} />
        <Switch>
          <Route
            path={`${routePrefix}/activity_categories`}
            component={Categories}
          />
          <Route
            path={`${routePrefix}/email_templates`}
            component={Email_Templates}
          />
          <Route
            path={`${routePrefix}/doc_templates`}
            component={Doc_Templates}
          />
          <Route
            path={`${routePrefix}/doc_types`}
            component={Doc_Types}
          />
          <Route
            path={`${routePrefix}/letter_header`}
            component={LetterHeading}
          />
          <Route
            path={`${routePrefix}/correspondents`}
            component={Correspondents}
          />
          <Route
            path={`${routePrefix}/client_custom_fields`}
            component={ContactCustomFields}
          />
          <Route
            path={`${routePrefix}/matter_custom_fields`}
            component={MatterCustomFields}
          />
          <Route
            exact
            path={`${routePrefix}/base_rates`}
            component={BaseRates}
          />
          <Route
            exact
            path={`${routePrefix}/payment_types`}
            component={PaymentTypesSettings}
          />
          <Route
            exact
            path={`${routePrefix}/salutations`}
            component={SalutationsSettings}
          />
          <Route
            exact
            path={`${routePrefix}/courts`}
            component={CourtsSettings}
          />
          <Route
            exact
            path={`${routePrefix}/agents`}
            component={AgentsSettings}
          />
          <Route
            exact
            path={`${routePrefix}/clio_matters`}
            component={ClioDocs}
          />
          <Route
            exact
            path={`${routePrefix}/users`}
            component={Users}
          />
          <Route path={`${routePrefix}/area_tasks`} component={AreaTasks} />
          <Route path={`${routePrefix}/whitelabeling`} component={Whitelabeling} />
          <Route path={`${routePrefix}/fee_scales`} component={FeeScaleSettings} />
          <Route exact path={`${routePrefix}/services`} component={Services} />
          <Route path={`${routePrefix}/checklists`} component={Checklists} />
          <Route exact path={`${routePrefix}/account/:org_id/purchase_license`} component={Purchase_License} />
          <Route exact path={`${routePrefix}/account/:org_id/edit`} component={AccountEdit} />
          <Route exact path={`${routePrefix}/account`} component={Account} />
          <Route exact path={`${routePrefix}`} component={Dash} history={history} />
          <Route path="*" component={NotFound} />
        </Switch>
      </SettingsProvider>
    </div>
  );
}
